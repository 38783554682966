export const NUMBER_1 = "+7 (991)336-64-90";
export const MAIL_1 = "CGfinans@yandex.ru";
export const NUMBER_2 = "+7 (958)242-26-71";
export const MAIL_2 = "CGfinans@mail.ru";

export const whatsupLink = "https://wa.me/79913366490"; //https://wa.me/79000000000
export const telegramLink = "https://t.me/fincon77"; //https://t.me/username
export const signalLink = "https://signal.me/fincon.77"; //https://signal.me/username

export const whatsupText = "Здравствуйте, меня интересует ваше предложение.";

export const BREAK_POINTS = {
  xs: 0,
  sm: 500,
  md: 768,
  lg: 1100,
  xl: 1350,
  xxl: 1400,
};

// для секции "Компании в продаже"
// чтобы добавить новую компанию, необходимо скопировать обьект:

//{
//   inn: "",
//   ifns: "",
//   accauns: "",
//   price: "Стоимость по запросу",
//},

//и вставить в этот массив снизу
export const COMPANIES = [
  {
    inn: "9715488417",
    ifns: "11.07.2024",
    accounts: "нет",
    price: "Стоимость по запросу",
    name: 'ООО МКК "Боливар"',
  },
   {
    inn: "9704241496",
    ifns: "18.04.2024",
    accounts: "Сбербанк",
    price: "Стоимость по запросу",
    name: '"Ломбард Фора"',
  },
  {
    inn: "9701271848",
    ifns: "26.12.2023",
    accounts: "Сбербанк",
    price: "Стоимость по запросу",
    name: '"Ломбард Триумф"',
  },
  {
    inn: "9704241312",
    ifns: "17.04.2024",
    accounts: "Сбербанк",
    price: "Стоимость по запросу",
    name: '"Ломбард Аквамарин"',
  },
  {
    inn: "9723233941",
    ifns: "02.07.2024",
    accounts: "нет",
    price: "Стоимость по запросу",
    name: 'ООО МКК "Титавин"',
  },
  {
    inn: "9727081841",
    ifns: "25.07.2024",
    accounts: "нет",
    price: "Стоимость по запросу",
    name: 'ООО МКК "Франк"',
  },
  {
    inn: "9727083528",
    ifns: "21.08.2024",
    accounts: "нет",
    price: "Стоимость по запросу",
    name: 'ООО МКК "Сентаво"',
  },
  {
    inn: "9703188842",
    ifns: "28.08.2024",
    accounts: "нет",
    price: "Стоимость по запросу",
    name: 'ООО МКК "Пенни"',
  },
  {
    inn: "9701295415",
    ifns: "28.08.2024",
    accounts: "нет",
    price: "Стоимость по запросу",
    name: 'ООО МКК "Центавр"',
  },
];

export const SERVICES = [
  {
    title: "Юридическое и бухгалтерское сопровождение финансовых организаций",
    list: [
      "Единоразовая сдача отчетов в ИФНС, фонды, Банк России, СРО и Росфинмониторинг для Микрокредитных компаний (МКК), Ломбардов и Кредитных потребительских кооперативов (КПК)",
      "Ведение бухгалтерского и юридического сопровождения деятельности МКК",
      "Ведение бухгалтерского и юридического сопровождения деятельности ломбардов",
      "Ведение бухгалтерского и юридического сопровождения деятельности КПК",
      "Разработка учетной политики для МКК, Ломбардов и КПК",
      "Ответы на запросы надзорных органов, представление интересов Клиента в надзорных органах и другие услуги по запросу Клиента для МКК, Ломбардов и КПК",
      "1 Ответы на запросы надзорных органов, представление интересов Клиента в надзорных органах и другие услуги по запросу Клиента для МКК, Ломбардов и КПК",
    ],
  },
  {
    title: "Услуги для участников микрофинансового рынка",
    list: [
      "Создание МКК, КПК и Ломбардов в любом регионе по запросу клиента,  регистрация в ИФНС любых изменений юр. лица, а также создание нового юр. лица",
      "Помощь с поиском юр. адреса, подбором сотрудников, запуском офиса",
      "Обучение сотрудников в форме Целевого Инструктажа либо повышение квалификации по 115 –ФЗ",
      "Сопровождение сделок купли-продажи МКК и Ломбардов, помощь в переоформлении КПК",
      "Выпуск ЭЦП физ. лица",
      "Изготовление печатей",
      "Выезд курьера по Москве и МО",
    ],
  },
];

export const FEEDBACK_LIST = [
  {
    name: 'Заказчик Дмитрий Ч.',
    description:
      "Обращались в компанию ФинансКонсалт с целью покупки микрокредитной компании, купили вновь созданное юридическое лицо с полным пакетом документов, все оформили у нотариуса, результатом довольны в полной мере. Рекомендуем.",
  },
  {
    name: 'Заказчик ООО МКК Блай',
    description:
      "Хотим выразить благодарность сотрудникам компании финансконсалт за помощь в организации бизнеса. Рассказали, подсказали, подготовили необходимые документы. Всегда идут навстречу просьбам партнёров. Большое спасибо!",
  },
  {
    name: 'Заказчик Роман Г.',
    description:
      "Покупали ООО, сделка прошла отлично! Квалифицированные, компетентные сотрудники, посоветовали, как лучше и правильно сделать без лишних затрат и хлопот. Сотрудники всегда на связи, консультируют до сих пор! Советую данную компанию, как надежную, быструю со штатом профессионалов своего дела",
  },
];

export const ABOUT_US_TITLE =
  "Ищете надежного партнера в сфере микрофинансирования?";

export const ABOUT_US = `Компания "ФинансКонсалт" имеет солидный профессиональный опыт и четко отработанные схемы взаимодействия с различными государственными органами, что позволяет нам заметно сэкономить время и существенно снизить затраты при разработке и оформлении любых бизнес-проектов.
Наша работа начинается с обсуждения проблем, побудивших заказчика обратиться к нашим услугам. На этом этапе выявляется предметная область будущего проекта, его границы и ожидаемые результаты. Предметом может быть вся организация, отдельные ее части или процессы.
Сотрудники компании помогут Вам как с решением первоочередных задач, связанных с организацией собственного дела: получением лицензий, ведением бухгалтерской отчетности, так и с юридическим сопровождением бизнеса.
Мы стремимся к комплексному и плодотворному сотрудничеству с Вами, а опыт работы позволяет нам соответствовать самым высоким требованиям.
Основные правила работы: индивидуальное решение, полное информирование клиента о ходе работ и максимальная оперативность для соблюдения установленных сроков.
Наша компания очень дорожит доверием своих клиентов!`;
