import { SectionHeading } from "../SectionHeading";
import styles from "./FeedBackForm.module.css";
import commonStyles from "../Common/Common.module.css";


export const FeedBackForm = () => {

  return (
    <section className={styles.container}>
      <div
        className={commonStyles.wrapper}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <SectionHeading>Свяжитесь с нами</SectionHeading>
       
      </div >
      <div className={commonStyles.wrapper}>
        <div className={styles.form}>
          <form action="mail.php" method="POST">

          <div className={styles.formGroup}>
            <label for="">Ваше имя</label>
            <input type="text" className={styles.formInput} id="" name="user_name" placeholder="Ваше имя"/>
          </div>

          <div className={styles.formGroup}>
            <label for="">Ваша организация</label>
            <input type="text" className={styles.formInput} id="" name="user_organization" placeholder="Название организации"/>
          </div>


          <div className={styles.formGroup}>
            <label for="">Ваш коментарий</label>
            <input type="text" className={styles.formInput} id="" name="user_comment" placeholder="Ваш комментарий"/>
          </div>


          <button type="submit" className={styles.button}>Отправить форму</button>
          </form>
        </div>
      </div >
    </section>
  );
};
